import React from "react";
import { AppType, Tag } from "~/typings/types";
import Link from "next/link";
import { getUrl, UrlEntity } from "~/helpers/urls";
import FakeLink from "~/components/FakeLink";
import { GetAppTypeAndSuffix } from "~/helpers/appListHelper";

type TagLinkListProps = {
  tags?: Tag[];
  appTypes?: AppType[];
  useFakeLinks?: boolean;
};

const TagLinkList = (props: TagLinkListProps) => {
  const { tags, useFakeLinks, appTypes } = props;

  return (
    <ul className="badges muted">
      {appTypes &&
        appTypes.map((appType, index) => {
          const linkType: UrlEntity =
            appType.type == "Feature" ? "feature" : "tag";
          const nextUrl = getUrl(linkType, [appType.urlName]);
          return (
            <li className="badge-more-important" key={index}>
              <Link prefetch={false} className="large" href={nextUrl}>
                {GetAppTypeAndSuffix(appType.appType, appType.titleSuffix)}
              </Link>
            </li>
          );
        })}

      {tags &&
        tags.map((tag, index) => {
          const linkType: UrlEntity = tag.type == "Feature" ? "feature" : "tag";
          const nextUrl = getUrl(linkType, [tag.urlName]);
          return (
            <li key={index}>
              {useFakeLinks ? (
                <FakeLink href={nextUrl}>
                  <span className="large">
                    {tag.name ? tag.name : tag.urlName}
                  </span>
                </FakeLink>
              ) : (
                <Link prefetch={false} className="large" href={nextUrl}>
                  {tag.name ? tag.name : tag.urlName}
                </Link>
              )}
            </li>
          );
        })}
    </ul>
  );
};
export default TagLinkList;
